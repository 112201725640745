import './App.css';
import React, { useState } from 'react';
import { Button, Modal, Dropdown, message } from 'antd'
import { DownOutlined, CopyOutlined, LogoutOutlined } from '@ant-design/icons';
import DropDownWallet from './components/DropDownWallet';
import Content from './components/home-page/Content';

function App() {
  const [walletAddress, setWalletAddress] = useState(null);
  const [walletAddressShort, setWalletAddressShort] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const shortenString = (str) => {
    const start = str.slice(0, 6);  // Lấy 6 ký tự đầu
    const end = str.slice(-4);     // Lấy 10 ký tự cuối
    return `${start}.....${end}`;   // Ghép thành chuỗi rút gọn
  };

  const handleMenuClick = (e) => {
    if (e.key === '1') {
      navigator.clipboard.writeText(walletAddress);
      console.log('click', e.key);
    } else {
      disconnetWallet();
    }
  };

  const items = [
    {
      label: 'Copy Address',
      key: '1',
      icon: <CopyOutlined />,
    },
    {
      label: 'Disconnect Wallet',
      key: '2',
      icon: <LogoutOutlined />
    }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const connectWallet = async () => {
    try {
      const provider = window.solana;
      if (provider && provider.isPhantom) {
        const connection = await provider.connect();
        setWalletAddress(connection.publicKey.toString());
        setWalletAddressShort(shortenString(connection.publicKey.toString()));
        handleCancel();
      } else {
        message.error('Phantom wallet not installed');
      }
    } catch (error) {
      message.error('Failed to connect wallet:', error);
    }
  };
  const disconnetWallet = async () => {
    try {
      const provider = window.solana;
      if (provider && provider.isPhantom) {
        await provider.disconnect();
        message.info('Disconnected from wallet');
        setWalletAddress(null);
        setWalletAddressShort(null);
      } else {
        message.error('Phantom wallet not installed');
      }
    } catch (error) {
      message.error('Failed to connect wallet:', error);
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    // Thực hiện hành động của bạn
  };

  // useEffect(() => {
  //   const onLoad = async () => {
  //     await checkIfWalletIsConnected();
  //   };
  //   window.addEventListener("load", onLoad);
  //   return () => window.removeEventListener("load", onLoad);

  // });

  return (
    <div className="App">
      <>
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          className="wallet-modal" // Tùy chỉnh class nếu cần
          style={{ zIndex: 999999 }}
        >
          <p className="header-wallet">Connect Wallet</p>
          <Button className="wallet-button" block>
            Binance Web3 Wallet
            <img
              src="https://cryptologos.cc/logos/binance-coin-bnb-logo.svg?v=023"
              alt="Binance"
            />
          </Button>
          <Button className="wallet-button" block>
            Avax
            <img
              src="https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=023"
              alt="Avax"
            />
          </Button>
          <Button className="wallet-button" block>
            MetaMask
            <img
              src="img/icons/metamask.svg"
              alt="MetaMask"
            />
          </Button>
          <Button className="wallet-button" onClick={connectWallet} block>
            Phantom
            <img
              src="https://cryptologos.cc/logos/phantasma-soul-logo.svg?v=023"
              alt="Phantom"
            />
          </Button>
          <Button className="wallet-button" block>
            Ethereum
            <img
              src="https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=023"
              alt="WalletConnect"
            />
          </Button>
          <Button className="wallet-button" block>
            Solana
            <img
              src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=023"
              alt="Solana"
            />
          </Button>
        </Modal>
      </>

      <nav className="navbar navbar-expand-lg navbar-white fixed-top shrink" id="banner" style={{zIndex: 99}}>
        <div className="container">
          <span><a className="navbar-brand" href="javascript:void(0)" onClick={handleClick}><span><img draggable="false" src="img/core-img/logo.png" alt="logo" /></span> </a></span> 
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"> 
            <span className="navbar-toggler-icon" /> 
            </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown"><span><a className="nav-link dropdown-toggle" href="javascript:void(0)" onClick={handleClick} id="navbardrop" data-toggle="dropdown">Home</a></span>
                </li>
              <li className="nav-item"><span><a className="nav-link" href="#about">About Us</a></span></li>
              <li className="nav-item"><span><a className="nav-link" href="#features">Features</a></span></li>
              <li className="nav-item"><span><a className="nav-link" href="#faq">FAQ</a></span></li>
              <li className="nav-item"><span><a className="nav-link" href="#roadmap">Roadmap</a></span></li>
              <li className="nav-item"><span><a className="nav-link" href="#contact">Contact</a></span></li>
              {walletAddressShort ? (
                <li className="lh-55px">
                  <span>
                    <DropDownWallet />
                  </span>
                  <span>
                    <Dropdown menu={menuProps}>
                      <button className="btn login-btn">
                        {walletAddressShort} <DownOutlined />
                      </button>
                    </Dropdown>
                  </span>
                </li>
              ) : (
                <li className="lh-55px">
                  <span>
                    <button onClick={showModal} className="btn login-btn ml-50">
                      Connect Wallet
                    </button>
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <Content />
    </div>
  )
}

export default App;
