const Content = () => {
    const handleClick = (event) => {
        event.preventDefault();
        // Thực hiện hành động của bạn
    };

    return (
        <>
            <section className="hero-section banner-bg section-padding relative" id="home">
                <div className="hero-section-content">
                    <div className="container ">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 col-md-12">
                                <div className="welcome-content">
                                    <div className="promo-section">
                                        <h3 className="special-head">Catch The Future and Hold More Memecoins</h3>
                                    </div>
                                    <h1 className="fadeInUp" data-wow-delay="0.2s">$MEMCO Future OF Meme Coins</h1>
                                    <p className="w-text fadeInUp" data-wow-delay="0.3s">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet dolorem blanditiis ad perferendis, labore delectus dolor sit amet, adipisicing elit. Eveniet ipsum dolor sit amet.</p>
                                    <div className="dream-btn-group fadeInUp" data-wow-delay="0.4s">
                                        <span><a href="javascript:void(0)" onClick={handleClick} className="btn more-btn mr-3">Whitepaper</a></span> <span><a href="javascript:void(0)" onClick={handleClick} className="btn more-btn">Buy Token</a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="hedo-wrapper floating-anim mt-s">
                                    <span><img src="img/core-img/header-img2.png" alt="" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-100 section-padding-100 clearfix" id="about">
                <div className="container">
                    <div className="d-inline-block">
                        <div className="contract-add align-items-center">
                            <i className="fa fa-copy" />
                            <h6 className="mr-3 mb-0">Smart Contract Address :</h6><span className="w-text"> 0xe3c127466908c2ccdc43521c8315b87fd369d605</span>
                            <span><img src="img/core-img/phase-1.png" className="symbol-img" width={100} alt="" /></span>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                            <div className="welcome-meter">
                                <span><img draggable="false" className="comp-img" src="img/core-img/about.png" alt="" /></span>
                                <span><img draggable="false" className="supportImg floating-anim" src="img/core-img/trade.PNG" alt="image" /></span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-0 mt-s">
                            <div className="who-we-contant">
                                <div className="dream-dots text-left fadeInUp" data-wow-delay="0.2s">
                                    <span className="gradient-text">About Our Trading Platform</span>
                                </div>
                                <h4 className="fadeInUp" data-wow-delay="0.3s">Decenteralized Crypto Trading Platform</h4>
                                <p className="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at dictum risus, non suscipit arcu. Quisque aliquam posuere tortor, sit amet convallis nunc scelerisque in.</p>
                                <p className="fadeInUp" data-wow-delay="0.5s">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit ipsa ut quasi adipisci voluptates, voluptatibus.</p><span><a className="btn more-btn mt-15 fadeInUp" data-wow-delay="0.6s" href="javascript:void(0)" onClick={handleClick}>Read More</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="darky how section-padding-0-70">
                <div className="container">
                    <div className="section-heading text-center">
                        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>
                            <span className="gradient-t green">How To Start</span>
                        </div>
                        <h2 className="wow fadeInUp" data-wow-delay="0.3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>How To Buy Tokens?</h2>
                        <p className="wow fadeInUp" data-wow-delay="0.4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="service_single_content box-shadow text-center mb-100 wow fadeInUp" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>
                                <div className="service_icon">
                                    <span><img draggable="false" src="img/icons/1.png" className="white-icon" alt="" /></span><span className="step-num">1</span>
                                </div>
                                <h6>Register New Account</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="service_single_content box-shadow text-center mb-100 wow wow fadeInUp" data-wow-delay="0.3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                                <div className="service_icon">
                                    <span><img draggable="false" src="img/icons/2.png" className="white-icon" alt="" /></span> <span className="step-num">2</span>
                                </div>
                                <h6>Setup Account Info</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="service_single_content box-shadow text-center mb-100 wow fadeInUp" data-wow-delay="0.4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>
                                <div className="service_icon">
                                    <span><img draggable="false" src="img/icons/3.png" className="white-icon" alt="" /></span> <span className="step-num">3</span>
                                </div>
                                <h6>Start Buying &amp; Selling</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-padding-0-100 relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-2 hidden-md">
                            <span><img className="hello-img" src="img/core-img/hello.png" alt="" /></span>
                        </div>
                        <div className="col-12 col-lg-10 col-md-12 pre-sale-bg">
                            <div className="row">
                                <div className="col-12 col-lg-6 col-md-12 ">
                                    <div className="ico-counter">
                                        <div className="counter-down">
                                            <div className="content">
                                                <div className="conuter-header">
                                                    <h3 className="w-text text-center">TOKEN SALE ENDS IN</h3>
                                                </div>
                                                <div className="counterdown-content">
                                                    <div className="count-down titled circled text-center">
                                                        <div className="simple_timer syotimer timer">
                                                            <div className="timer-head-block" />
                                                            <div className="timer-body-block">
                                                                <div className="table-cell day">
                                                                    <div className="tab-val">
                                                                        441
                                                                    </div>
                                                                    <div className="tab-metr tab-unit">
                                                                        days
                                                                    </div>
                                                                </div>
                                                                <div className="table-cell hour">
                                                                    <div className="tab-val">
                                                                        01
                                                                    </div>
                                                                    <div className="tab-metr tab-unit">
                                                                        hour
                                                                    </div>
                                                                </div>
                                                                <div className="table-cell minute">
                                                                    <div className="tab-val">
                                                                        19
                                                                    </div>
                                                                    <div className="tab-metr tab-unit">
                                                                        minutes
                                                                    </div>
                                                                </div>
                                                                <div className="table-cell second">
                                                                    <div className="tab-val" style={{ opacity: 1 }}>
                                                                        44
                                                                    </div>
                                                                    <div className="tab-metr tab-unit">
                                                                        seconds
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="timer-foot-block" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-md-12 mt-s">
                                    <div className="ico-progress">
                                        <ul className="list-unstyled list-inline clearfix mb-10">
                                            <li className="title">33m</li>
                                            <li className="strength">75m</li>
                                        </ul>
                                        <div className="current-progress">
                                            <div className="progress-bar has-gradient" style={{ width: '75%' }} />
                                        </div><span className="pull-left">Softcap in 103 days</span> <span className="pull-right">Token Hardcap</span>
                                    </div>
                                    <h4 className="pre-price">Pre-Sale 1 ETH = 8,000 Token</h4><span><span><img src="img/core-img/payment.png" alt="" /></span></span>
                                    <div className="text-left">
                                        <span><a className="btn dream-btn mt-30 fadeInUp" data-wow-delay="0.6s" href="javascript:void(0)" onClick={handleClick}>Buy More tokens</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="roadmap section-padding-0-100" id="roadmap">
                <div className="section-heading text-center">
                    <div className="dream-dots justify-content-center fadeInUp" data-wow-delay="0.2s">
                        <span className="gradient-text blue">ICO Roadmap</span>
                    </div>
                    <h2 className="fadeInUp" data-wow-delay="0.3s">Our ICO Roadmap</h2>
                    <p className="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
                        accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-timeline">
                                <div className="timeline">
                                    <div className="icon" />
                                    <div className="date-content">
                                        <div className="date-outer">
                                            <span className="date"> <span className="month">22 Sept</span> <span className="year">2024</span> </span>
                                        </div>
                                    </div>
                                    <div className="timeline-content">
                                        <span className="arrowo" />
                                        <h5 className="title">Listing to the major exchanges</h5>
                                        <p className="description text-light-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                            efficitur ex sit amet massa scelerisque scelerisque. Aliquam erat volutpat. Aenean interdum finibus
                                            efficitur. Praesent dapibus dolor felis, eu ultrices elit molestie.</p>
                                    </div>
                                </div>
                                <div className="timeline">
                                    <div className="icon" />
                                    <div className="date-content">
                                        <div className="date-outer">
                                            <span className="date"> <span className="month">27 Nov</span> <span className="year">2024</span> </span>
                                        </div>
                                    </div>
                                    <div className="timeline-content">
                                        <span className="arrowo" />
                                        <h5 className="title">Platform integration to marketplaces</h5>
                                        <p className="description text-light-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                            efficitur ex sit amet massa scelerisque scelerisque. Aliquam erat volutpat. Aenean interdum finibus
                                            efficitur. Praesent dapibus dolor felis, eu ultrices elit molestie.</p>
                                    </div>
                                </div>
                                <div className="timeline">
                                    <div className="icon" />
                                    <div className="date-content">
                                        <div className="date-outer">
                                            <span className="date"> <span className="month">19 Dec</span> <span className="year">2024</span> </span>
                                        </div>
                                    </div>
                                    <div className="timeline-content">
                                        <span className="arrowo" />
                                        <h5 className="title">Crypto Wallet version release</h5>
                                        <p className="description text-light-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                            efficitur ex sit amet massa scelerisque scelerisque. Aliquam erat volutpat. Aenean interdum finibus
                                            efficitur. Praesent dapibus dolor felis, eu ultrices elit molestie.</p>
                                    </div>
                                </div>
                                <div className="timeline">
                                    <div className="icon" />
                                    <div className="date-content">
                                        <div className="date-outer">
                                            <span className="date"> <span className="month">25 Jan</span> <span className="year">2024</span> </span>
                                        </div>
                                    </div>
                                    <div className="timeline-content">
                                        <span className="arrowo" />
                                        <h5 className="title">Platform ealier version development</h5>
                                        <p className="description text-light-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                            efficitur ex sit amet massa scelerisque scelerisque. Aliquam erat volutpat. Aenean interdum finibus
                                            efficitur. Praesent dapibus dolor felis, eu ultrices elit molestie.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-padding-100 banner-bg relative" id="features">
                <div className="bubble-wrapper">
                    <span><img src="img/core-img/chat-bubble.png" alt="" /></span>
                    <h2 className="coin-text">$MEMCO</h2>
                </div>
                <div className="container">
                    <div className="section-heading text-center">
                        <div className="dream-dots justify-content-center fadeInUp" data-wow-delay="0.2s">
                            <span className="gradient-t green">About Our Token</span>
                        </div>
                        <h2 className="fadeInUp" data-wow-delay="0.3s">Our Token Info</h2>
                        <p className="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
                            accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-5 col-md-12">
                            <span><img src="img/core-img/dist.png" alt="" /></span>
                        </div>
                        <div className="col-12 col-lg-7 col-sm-12">
                            <div className="col-md-6 col-sm-6">
                                <div className="who-we-contant mt-s">
                                    <ul className="token-information">
                                        <li><span><span><img src="img/icons/t1.png" alt="" /></span></span>
                                            <h6>Token Name</h6>
                                            <p>Crypto Token</p>
                                        </li>
                                        <li><span><span><img src="img/icons/t2.png" alt="" /></span></span>
                                            <h6>ICO Pre-sale starts in</h6>
                                            <p>June 15-30, 2025</p>
                                        </li>
                                        <li><span><span><img src="img/icons/t3.png" alt="" /></span></span>
                                            <h6>Platform</h6>
                                            <p>Ethereum based</p>
                                        </li>
                                        <li><span><span><img src="img/icons/t4.png" alt="" /></span></span>
                                            <h6>PreICO price</h6>
                                            <p>1 ETH = 2,682 Token</p>
                                        </li>
                                        <li><span><span><img src="img/icons/t5.png" alt="" /></span></span>
                                            <h6>Price in ICO</h6>
                                            <p className="mb-0">1 ETH = 2,182 Token</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="who-we-contant mt-s">
                                    <ul className="token-information">
                                        <li><span><span><img src="img/icons/t6.png" alt="" /></span></span>
                                            <h6>Token Soft cap</h6>
                                            <p>$18.000.000</p>
                                        </li>
                                        <li><span><span><img src="img/icons/t8.png" alt="" /></span></span>
                                            <h6>Token Hard Cap</h6>
                                            <p>$78.000.000</p>
                                        </li>
                                        <li><span><span><img src="img/icons/t7.png" alt="" /></span></span>
                                            <h6>Total Supply</h6>
                                            <p>250.000.000 Token</p>
                                        </li>
                                        <li><span><span><img src="img/icons/t2.png" alt="" /></span></span>
                                            <h6>Sold Tokens</h6>
                                            <p>49,828,000 Token</p>
                                        </li>
                                        <li><span><span><img src="img/icons/t9.png" alt="" /></span></span>
                                            <h6>Remaining Tokens</h6>
                                            <p className="mb-0">200,182,000 Token</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="special fuel-features section-padding-100 clearfix">
                <div className="container has-shadow relative">
                    <span><img src="img/core-img/star.png" className="star-img" alt="" /></span>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                            <div className="welcome-meter relative " data-wow-delay="0.7s">
                                <span><img draggable="false" className="center-block" src="img/core-img/whitepaper1.png" alt="" /></span>
                                <span><img draggable="false" className="trade1 floating-anim" src="img/core-img/trade1.png" alt="" /></span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-0">
                            <div className="who-we-contant">
                                <div className="dream-dots text-left fadeInUp" data-wow-delay="0.2s">
                                    <span className="gradient-text blue">Our ICO Whitepaper</span>
                                </div>
                                <h4 className="fadeInUp" data-wow-delay="0.3s">Downoad Our Whitepaper</h4>
                                <p className="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
                                    qui iste asperiores harum
                                    maiores praesentium facere ullam blanditiis, odio dolorum. Officia quisquam eaque suscipit facere ducimus,
                                    sit quaerat. Numquam,
                                    corrupti?</p><span><a className="btn more-btn mt-15 fadeInUp" data-wow-delay="0.6s" href="javascript:void(0)" onClick={handleClick}>Download
                                        Now</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="faq-timeline-area section-padding-100-85" id="faq">
                <div className="container">
                    <div className="section-heading text-center">
                        <div className="dream-dots justify-content-center fadeInUp" data-wow-delay="0.2s">
                            <span className="gradient-t green">Token FAQ</span>
                        </div>
                        <h2 className="fadeInUp" data-wow-delay="0.3s">Frequently Questions</h2>
                        <p className="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
                            accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-12">
                            <span><img draggable="false" src="img/core-img/faq.png" alt="" className="center-block img-responsive" /></span>
                        </div>
                        <div className="col-12 col-lg-6 col-md-12">
                            <div className="dream-faq-area mt-s ">
                                <dl style={{ marginBottom: 0 }}>
                                    <dt className="wave fadeInUp" data-wow-delay="0.2s">
                                        What are the objectives of this Token?
                                    </dt>
                                    <dd className="fadeInUp" data-wow-delay="0.3s">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore omnis quaerat nostrum, pariatur
                                            ipsam sunt accusamus enim necessitatibus est fugiat, assumenda dolorem, deleniti corrupti cupiditate
                                            ipsum, dolorum voluptatum esse error?</p>
                                    </dd>
                                    <dt className="wave fadeInUp" data-wow-delay="0.3s">
                                        What is the best features and services we deiver?
                                    </dt>
                                    <dd style={{ display: 'none' }}>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore omnis quaerat nostrum, pariatur
                                            ipsam sunt accusamus enim necessitatibus est fugiat, assumenda dolorem, deleniti corrupti cupiditate
                                            ipsum, dolorum voluptatum esse error?</p>
                                    </dd>
                                    <dt className="wave fadeInUp" data-wow-delay="0.4s">
                                        Why this ICO important to me?
                                    </dt>
                                    <dd style={{ display: 'none' }}>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore omnis quaerat nostrum, pariatur
                                            ipsam sunt accusamus enim necessitatibus est fugiat, assumenda dolorem, deleniti corrupti cupiditate
                                            ipsum, dolorum voluptatum esse error?</p>
                                    </dd>
                                    <dt className="wave fadeInUp" data-wow-delay="0.5s">
                                        how may I take part in and purchase this Token?
                                    </dt>
                                    <dd style={{ display: 'none' }}>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore omnis quaerat nostrum, pariatur
                                            ipsam sunt accusamus enim necessitatibus est fugiat, assumenda dolorem, deleniti corrupti cupiditate
                                            ipsum, dolorum voluptatum esse error?</p>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="our_team_area section-padding-0-0 clearfix" id="team">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                <div className="dream-dots justify-content-center fadeInUp" data-wow-delay="0.2s">
                                    <span className="gradient-text blue">Our Team</span>
                                </div>
                                <h2 className="fadeInUp" data-wow-delay="0.3s">Awesome Team</h2>
                                <p className="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
                                    accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <div className="single-team-member fadeInUp" data-wow-delay="0.2s">
                                <div className="team-member-thumb">
                                    <span><img draggable="false" src="img/team-img/1.png" className="center-block" alt="" /></span>
                                </div>
                                <div className="team-info">
                                    <h5 className="w-text">Joman Helal</h5>
                                    <p className="g-text">Executive Officer</p>
                                </div>
                                <div className="team-social-icon">
                                    <span><a href="javascript:void(0)" onClick={handleClick}><i className="fa fa-linkedin" /></a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <div className="single-team-member fadeInUp" data-wow-delay="0.3s">
                                <div className="team-member-thumb">
                                    <span><img draggable="false" src="img/team-img/2.png" className="center-block" alt="" /></span>
                                </div>
                                <div className="team-info">
                                    <h5 className="w-text">Slans Alons</h5>
                                    <p className="g-text">Business Development</p>
                                </div>
                                <div className="team-social-icon">
                                    <span><a href="javascript:void(0)" onClick={handleClick}><i className="fa fa-linkedin" /></a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <div className="single-team-member fadeInUp" data-wow-delay="0.4s">
                                <div className="team-member-thumb">
                                    <span><img draggable="false" src="img/team-img/3.png" className="center-block" alt="" /></span>
                                </div>
                                <div className="team-info">
                                    <h5 className="w-text">Josha Michal</h5>
                                    <p className="g-text">UX/UI Designer</p>
                                </div>
                                <div className="team-social-icon">
                                    <span><a href="javascript:void(0)" onClick={handleClick}><i className="fa fa-linkedin" /></a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <div className="single-team-member fadeInUp" data-wow-delay="0.5s">
                                <div className="team-member-thumb">
                                    <span><img draggable="false" src="img/team-img/4.png" className="center-block" alt="" /></span>
                                </div>
                                <div className="team-info">
                                    <h5 className="w-text">Johan Wright</h5>
                                    <p className="g-text">Head of Marketing</p>
                                </div>
                                <div className="team-social-icon">
                                    <span><a href="javascript:void(0)" onClick={handleClick}><i className="fa fa-linkedin" /></a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="subscribe section-padding-0-100">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="subscribe-wrapper">
                                <div className="section-heading text-center">
                                    <h2 className="wow fadeInUp" data-wow-delay="0.3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>Don’t Miss ICO News And
                                        Updates!</h2>
                                    <p className="wow fadeInUp" data-wow-delay="0.4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>Lorem ipsum dolor sit
                                        amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
                                </div>
                                <div className="service-text text-center">
                                    <div className="subscribe-section has-shadow">
                                        <div className="input-wrapper">
                                            <i className="fa fa-home" /> <input type="email" placeholder="Enter your Email" />
                                        </div><button className="btn more-btn">Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer-area bg-img" style={{ backgroundImage: 'url(./img/bg-img/pattern.png)' }}>
                <div className="contact_us_area section-padding-0-0" id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-heading text-center">
                                    <div className="dream-dots justify-content-center fadeInUp" data-wow-delay="0.2s">
                                        <span className="gradient-text">Contact us</span>
                                    </div>
                                    <h2 className="fadeInUp" data-wow-delay="0.3s">Contact With Us</h2>
                                    <p className="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                        quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8">
                                <div className="contact_form">
                                    <form action="#" method="post" id="main_contact_form" noValidate>
                                        <div className="row">
                                            <div className="col-12">
                                                <div id="success_fail_info" />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="group fadeInUp" data-wow-delay="0.2s">
                                                    <input type="text" name="name" id="name" required /> <span className="highlight" /> <span className="bar" /> <label>Name</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="group fadeInUp" data-wow-delay="0.3s">
                                                    <input type="text" name="email" id="email" required /> <span className="highlight" /> <span className="bar" /> <label>Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="group fadeInUp" data-wow-delay="0.4s">
                                                    <input type="text" name="subject" id="subject" required /> <span className="highlight" /> <span className="bar" /> <label>Subject</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="group fadeInUp" data-wow-delay="0.5s">
                                                    <textarea name="message" id="message" required defaultValue={""} /> <span className="highlight" /> <span className="bar" /> <label>Message</label>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center fadeInUp" data-wow-delay="0.6s">
                                                <button type="submit" className="btn more-btn">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-content-area ">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 col-lg-4 col-md-6">
                                <div className="footer-copywrite-info">
                                    <div className="copywrite_text fadeInUp" data-wow-delay="0.2s">
                                        <div className="footer-logo">
                                            <span><a href="javascript:void(0)" onClick={handleClick}><span><img draggable="false" src="img/core-img/logo.png" alt="logo" /></span></a></span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit ducimus voluptatibus neque illo id
                                            repellat quisquam? Autem expedita earum quae laborum ipsum ad.</p>
                                    </div>
                                    <div className="footer-social-info fadeInUp" data-wow-delay="0.4s">
                                        <span><a href="javascript:void(0)" onClick={handleClick}><i className="fa fa-facebook" aria-hidden="true" /></a></span> <span><a href="javascript:void(0)" onClick={handleClick}> <i className="fa fa-twitter" aria-hidden="true" /></a></span> <span><a href="javascript:void(0)" onClick={handleClick}><i className="fa fa-google-plus" aria-hidden="true" /></a></span> <span><a href="javascript:void(0)" onClick={handleClick}><i className="fa fa-instagram" aria-hidden="true" /></a></span> <span><a href="javascript:void(0)" onClick={handleClick}><i className="fa fa-linkedin" aria-hidden="true" /></a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-6">
                                <div className="contact_info_area d-sm-flex justify-content-between">
                                    <div className="contact_info mt-x text-center fadeInUp" data-wow-delay="0.3s">
                                        <h5>PRIVACY &amp; TOS</h5><span><a href>
                                            <p>Advertiser Agreement</p>
                                        </a></span> <span><a href>
                                            <p>Acceptable Use Policy</p>
                                        </a></span> <span><a href>
                                            <p>Privacy Policy</p>
                                        </a></span> <span><a href>
                                            <p>Technology Privacy</p>
                                        </a></span> <span><a href>
                                            <p>Developer Agreement</p>
                                        </a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-2 col-md-6 ">
                                <div className="contact_info_area d-sm-flex justify-content-between">
                                    <div className="contact_info mt-s text-center fadeInUp" data-wow-delay="0.2s">
                                        <h5>NAVIGATE</h5><span><a href>
                                            <p>Advertisers</p>
                                        </a></span> <span><a href>
                                            <p>Developers</p>
                                        </a></span> <span><a href>
                                            <p>Resources</p>
                                        </a></span> <span><a href>
                                            <p>Company</p>
                                        </a></span> <span><a href>
                                            <p>Connect</p>
                                        </a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 col-md-6 ">
                                <div className="contact_info_area d-sm-flex justify-content-between">
                                    <div className="contact_info mt-s text-center fadeInUp" data-wow-delay="0.4s">
                                        <h5>CONTACT US</h5>
                                        <p>Mailing Address:xx00 E. Union Ave</p>
                                        <p>Suite 1100. Denver, CO 80237</p>
                                        <p>+999 90932 627</p>
                                        <p>support@yourdomain.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    );
}

export default Content;