import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons';


const DropDownWallet = () => {
    const handleMenuClick = (e) => {
        if (e.key === '1') {
            console.log('click', e.key);
        } else {
        }
    };

    const items = [
        {
            label: 'Binance Web3 Wallet',
            key: '1',
            icon: <img style={{height: 20}}
            src="https://cryptologos.cc/logos/binance-coin-bnb-logo.svg?v=023"
            alt="Binance"
          />,
        },
        {
            label: 'MetaMask',
            key: '2',
            icon: <img style={{height: 20}}
            src="img/icons/metamask.svg"
            alt="MetaMask"
          />,
        },
        {
            label: 'Avax',
            key: '3',
            icon: <img style={{height: 20}}
            src="https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=023"
            alt="Avax"
          />,
        },
        {
            label: 'Solana',
            key: '4',
            icon: <img style={{height: 20}}
            src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=023"
            alt="Solana"
          />,
        }
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown menu={menuProps}>
            <button className="btn login-btn">
                <img style={{ paddingRight: 10, height: 20 }}
                    src="https://cryptologos.cc/logos/phantasma-soul-logo.svg?v=023"
                    alt="Phantom"
                />
                Phantom <DownOutlined />
            </button>
        </Dropdown>
    );
}

export default DropDownWallet;